Array.from =
  Array.from
  || function (b, c, d) {
    c =
      c != null
        ? c
        : function (x) {
          return x
        }
    const e = []
    let f =
      typeof Symbol !== 'undefined' && Symbol.iterator && b[Symbol.iterator]
    if (typeof f === 'function') {
      b = f.call(b)
      for (var g = 0; !(f = b.next()).done;) e.push(c.call(d, f.value, g++))
    } else for (f = b.length, g = 0; g < f; g++) e.push(c.call(d, b[g], g))
    return e
  }
